import { createSlice, createAsyncThunk, combineReducers } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk example
export const NhsVerificationSend = createAsyncThunk(
  'nhsverification/NhsVerificationSend',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post('auth/nhs-patient-verification',params);
      return response.data; // Assumes response.data = { data }
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An unexpected error occurred');
    }
  }
);

// Slice definition
export const nhsverificationSlice = createSlice({
  name: 'nhsverification',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    resetnhsverificationState: (state) => {
      state.data = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(NhsVerificationSend.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(NhsVerificationSend.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(NhsVerificationSend.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// Selectors example
export const selectnhsverificationData = (state) => state.nhsverification.data;
export const selectnhsverificationLoading = (state) => state.nhsverification.isLoading;
export const selectnhsverificationError = (state) => state.nhsverification.error;

// Export actions and reducer
export const { resetnhsverificationState } = nhsverificationSlice.actions;


// Combine reducers
const reducer = combineReducers({
  nhsverification: nhsverificationSlice.reducer,
});

export const name = "nhsverification"; // Key for use in the Redux store

// Export actions and reducer
export const { resetNhsverificationState } = nhsverificationSlice.actions;
export default reducer;
// Function to set user data
export function setUser(data) {
  localStorage.setItem("userData", JSON.stringify(data));
}

// Function to get user data with validation
export function getUser() {
  const userData = localStorage.getItem("userData");
  return userData ? JSON.parse(userData) : null;
}

// Function to set refreshToken
export function setRefreshToken(data) {
  localStorage.setItem("refreshAppToken", JSON.stringify(data));
}

// Function to get refreshToken with validation
export function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshAppToken");
  // console.log("sss", refreshToken)
  return refreshToken ? JSON.parse(refreshToken) : null;
}

// Function to set account data
export function setAccount(data) {
  localStorage.setItem("accountData", JSON.stringify(data));
}

// Function to get account data with validation
export function getAccount() {
  const accountData = localStorage.getItem("accountData");
  return accountData ? JSON.parse(accountData) : null;
}

// Function to set JWT Token
export function setToken(data) {
  localStorage.setItem("jwt_app_access_token", data);
}

// Function to get JWT Token with validation
export function getToken() {
  const token = localStorage.getItem("jwt_app_access_token");
  return token ? token : null;
}

// Function to clear all data from localStorage and sessionStorage
export function clearStorageAndSession() {
  localStorage.clear();
  sessionStorage.clear();
}

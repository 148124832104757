import React from "react";
import { CiClock2 } from "react-icons/ci";

const ObservationCard = ({ observation }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'final':
        return '#28a745'; // Green for final status
      case 'preliminary':
        return '#ffc107'; // Yellow for preliminary
      case 'amended':
        return '#17a2b8'; // Blue for amended
      default:
        return '#6c757d'; // Gray for other statuses
    }
  };

  const getCategoryColor = (category) => {
    // Customize colors based on category
    switch (category.toLowerCase()) {
      case 'investigation results':
        return '#4A90E2'; // Blue
      default:
        return '#6c757d'; // Gray
    }
  };

  return (
    <div
      className="relative min-h-[70px] rounded-[6px] bg-[#FAFAFA] flex flex-col justify-start items-start p-[1rem] gap-[0.5rem] w-full"
    >
      <div className="flex justify-between w-full">
        <div className="flex flex-col w-fit">
          <div
            style={{ backgroundColor: getCategoryColor(observation.category) }}
            className="flex justify-center items-center text-[17px] font-[600] text-white rounded-full w-[49px] h-[49px]"
          >
            <span>{observation.observation.charAt(0)}</span>
          </div>
          <h1 className="text-[16px] font-[500] mt-[0.5rem]">
            {observation.observation}
          </h1>
          <span className="text-[12px] font-[400]">
            Onset: {new Date(observation.effectiveDate).toLocaleDateString()}
          </span>
        </div>
        <div className="flex flex-col justify-center">
          <span
            className="text-[12px] font-[400]"
            style={{ color: getStatusColor(observation.status) }}
          >
            {observation.status}
          </span>
        </div>
      </div>
      <div className="text-[12px] font-[400] text-gray-600 mt-[0.5rem]">
        Encounter: {observation.encounter}
      </div>
      <div className="absolute right-[5px] top-[5px] flex flex-col h-full py-[5px] justify-between items-end">
        <CiClock2 />
        <span
          className="text-[12px]"
          style={{ color: getStatusColor(observation.status) }}
        >
          {observation.status}
        </span>
      </div>
    </div>
  );
};

export default ObservationCard;

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import avatar from "@/../../public/assets/images/avatar.png";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import InputField from "@medoczen/core/InputField/InputField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdOutlineCalendarToday } from "react-icons/md";
import CountriesDropdown from "@medoczen/core/CountriesDropdown/CountriesDropdown";
import InputLabel from "@mui/material/InputLabel";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  AddMember,
  DeleteMember,
  UpdateMember,
  getMember,
} from "./store/membersSlice";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  FormLabel,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import SkeletonPage from "./SkeletonPage";
import DropDownSheet from "@medoczen/core/DropDownSheet/DropDownSheet";
import NhsVerificationDialog from "@medoczen/core/NHSVerification/NhsVerification";
import AddressDialog from "./AddressDialog";
import AddressModifyConfirmDialog from "./AddressModifyConfirmDialog";
import { BiPlusCircle } from "react-icons/bi";
import { ChevronRight } from "@mui/icons-material";

function AddFamilyMembers(props) {
  const methods = useForm();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = methods;
  const form = watch();
  const [tempImage, setTempImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const selectMember = useSelector((state) => state.member.membersSlice);
  // State and handler for tabs
  const [tabValue, setTabValue] = useState(0);
  const [addressEditConfirmDialogOpen, setAddressEditConfirmDialogOpen] =
    useState({ type: "", open: false });
  const [addressEditDialogOpen, setAddressEditDialogOpen] = useState({
    type: "",
    open: false,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function handleOpenAddressEditDialog(type) {
    setAddressEditConfirmDialogOpen({
      type: "",
      open: false,
    });
    setAddressEditDialogOpen({ type, open: true });
  }

  function handleCloseAddressEditDialog() {
    setAddressEditDialogOpen({ type: "", open: false });
  }
  function handleOpenAddressEditConfirmDialog(type) {
    setAddressEditConfirmDialogOpen({
      type,
      open: true,
    });
  }
  function handleCloseAddressEditConfirmDialog() {
    setAddressEditConfirmDialogOpen({ type: "", open: false });
  }

  useEffect(() => {
    if (props.update) {
      reset(selectMember.getMember_success);
    }
  }, [selectMember]);

  function onSubmit() {
    if (props.update) {
      dispatch(UpdateMember({ ...form, id: memberId })).then(() => {
        props.navigate("/family-members");
      });
    } else {
      dispatch(AddMember(form)).then(() => {
        props.navigate("/family-members");
      });
    }
  }

  const updateAddress = (data) => {
    setLoading(true);
    setAddressEditDialogOpen({ type: "", open: false });
    dispatch(UpdateMember(form)).then(() => {
      setTempImage(null);
      fetchData();
    });
  };

  function RemoveAddress(type) {
    const updatedAddresses = { ...form.addresses };

    // Remove the address for the given type
    if (updatedAddresses[type]) {
      delete updatedAddresses[type];
    }

    // Update the form state with the modified addresses
    setValue("addresses", updatedAddresses);

    // Show loading and close dialog
    setLoading(true);
    setAddressEditConfirmDialogOpen({ type: "", open: false });

    // Dispatch the updated details
    dispatch(UpdateMember({ ...form, addresses: updatedAddresses })).then(
      () => {
        setTempImage(null);
        fetchData();
      }
    );
  }

  function fetchData() {
    setLoading(true);
    dispatch(getMember(memberId))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching members:", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.update) {
      setLoading(true);
      fetchData();
    }
  }, []);

  function HandleDelete() {
    if (props.update) {
      dispatch(DeleteMember({ ...form, id: memberId })).then(() => {
        props.navigate("/family-members");
      });
    }
  }

  if (loading) {
    return <SkeletonPage />;
  }

  return (
    <div>
      <>
        <HeaderWrapper
          title={`${props.update ? "Edit" : "Add"} Family Member`}
          path={"family-members"}
        >
          {props?.update && (
            <button onClick={() => HandleDelete()}>
              <FaTrashAlt size={20} color="white" />
            </button>
          )}
        </HeaderWrapper>
        <div className="flex flex-col items-center px-4 py-3 w-full">
          {/* PersonalDetails Image Upload */}
          <div className="w-full flex justify-center pb-5">
            <Controller
              name="selfie"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <label className="relative">
                  <img
                    src={
                      tempImage
                        ? tempImage
                        : `${
                            form?.selfie
                              ? `${form.selfie}?${new Date().getTime()}`
                              : `${avatar}?${new Date().getTime()}`
                          }`
                    }
                    className="w-[100px] h-[100px] rounded-full"
                  />

                  <input
                    id="image"
                    name={name}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        const file = e.target.files[0];

                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setTempImage(reader.result);
                          onChange({
                            name: file.name,
                            type: file.type,
                            file: reader.result,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    onBlur={onBlur}
                    ref={ref}
                  />
                  <div className="absolute right-0 bottom-0 bg-white w-[40px] h-[40px] rounded-full flex justify-center items-center shadow-md cursor-pointer">
                    <FaRegEdit size={20} />
                  </div>
                </label>
              )}
            />
          </div>

          {form?.id && props?.update && (
            <>
              {form?.nhs_verified ? (
                <VerifiedIcon color="primary" />
              ) : (
                <NhsVerificationDialog
                  user_id={form?.id}
                  is_member={true}
                  action={fetchData}
                />
              )}
            </>
          )}

          <div className="w-full">
            <div className="w-full  pb-5">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ textTransform: "none" }}
                variant="fullWidth"
              >
                <Tab label="General" />
                <Tab label="Address" />
                {/* <Tab label="Tab Three" /> */}
              </Tabs>
            </div>

            {tabValue === 0 && (
              <>
                <div className="mb-3">
                  <Controller
                    name="full_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputField {...field} label="Name" className="mb-2" />
                    )}
                  />
                </div>

                <div className="mb-3 col-span-2">
                  <Controller
                    name="nhs_number"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputField
                        {...field}
                        type="text"
                        label="NHS Number"
                        className="mb-2"
                      />
                    )}
                  />
                </div>

                <div className="mb-3">
                  <Controller
                    name="birthday"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MDatePicker
                        label="birthday"
                        name="birthday"
                        disableFuture={true}
                        control={control}
                        icon={<MdOutlineCalendarToday size={24} />}
                        className="mb-2"
                      />
                    )}
                  />
                </div>

                {/* Gender Field with DropDownSheet */}
                <div className="mb-3">
                  <Controller
                    name="gender_id"
                    control={control}
                    render={({ field }) => (
                      <DropDownSheet
                        field={field}
                        label="Gender"
                        options={[
                          { value: "1", label: "Male" },
                          { value: "2", label: "Female" },
                          { value: "3", label: "Other" },
                        ]}
                      />
                    )}
                  />
                </div>

                {/* Family Relationship Type Field with DropDownSheet */}
                <div className="mb-3">
                  <Controller
                    name="family_relationship_type_id"
                    control={control}
                    render={({ field }) => (
                      <DropDownSheet
                        field={field}
                        label="Family Relationship Type"
                        options={[
                          { value: "1", label: "Mother" },
                          { value: "2", label: "Father" },
                          { value: "3", label: "Son" },
                          { value: "4", label: "Daughter" },
                          { value: "5", label: "Uncle" },
                          { value: "6", label: "Aunt" },
                          { value: "7", label: "Sister" },
                          { value: "8", label: "Brother" },
                        ]}
                      />
                    )}
                  />
                </div>

                <div className="mb-[4rem]">
                  <FormControl fullWidth>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <CountriesDropdown
                          label="Country"
                          field={field}
                          setValue={setValue}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </>
            )}

            {tabValue === 1 && (
              <div className="w-full">
                {/* Home Address */}

                {form?.addresses?.home ? (
                  <Card className="relative" style={{ margin: "16px 0" }}>
                    <CardContent>
                      <div className="flex  items-center">
                        <div className="flex-[12]">
                          <Typography variant="h6" gutterBottom>
                            Home Address
                          </Typography>
                          <Typography>
                            <strong>City:</strong> {form?.addresses?.home?.city}
                          </Typography>
                          <Typography>
                            <strong>Street:</strong>{" "}
                            {form?.addresses?.home?.street}
                          </Typography>
                          <Typography>
                            <strong>Post Code:</strong>{" "}
                            {form?.addresses?.home?.post_code}
                          </Typography>
                        </div>
                        <div className="flex-[1]">
                          <IconButton
                            onClick={() =>
                              handleOpenAddressEditConfirmDialog("home")
                            }
                          >
                            {" "}
                            <ChevronRight />
                          </IconButton>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <Card className="relative" style={{ margin: "16px 0" }}>
                    <CardContent>
                      <div className="flex  flex-col items-center">
                        <Typography variant="h6" gutterBottom>
                          Add Home Address
                        </Typography>

                        <div className="">
                          <IconButton
                            onClick={() => handleOpenAddressEditDialog("home")}
                          >
                            {" "}
                            <BiPlusCircle size={30} />
                          </IconButton>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                )}

                {/* Temporary Address */}
                {form?.addresses?.temporary ? (
                  <Card className="relative" style={{ margin: "16px 0" }}>
                    <CardContent>
                      <div className="flex  items-center">
                        <div className="flex-[12]">
                          <Typography variant="h6" gutterBottom>
                            Temporary Address
                          </Typography>
                          <Typography>
                            <strong>City:</strong>{" "}
                            {form?.addresses?.temporary?.city}
                          </Typography>
                          <Typography>
                            <strong>Street:</strong>{" "}
                            {form?.addresses?.temporary?.street}
                          </Typography>
                          <Typography>
                            <strong>Post Code:</strong>{" "}
                            {form?.addresses?.temporary?.post_code}
                          </Typography>
                        </div>
                        <div className="flex-[1]">
                          <IconButton
                            onClick={() =>
                              handleOpenAddressEditConfirmDialog("temporary")
                            }
                          >
                            {" "}
                            <ChevronRight />
                          </IconButton>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <Card className="relative" style={{ margin: "16px 0" }}>
                    <CardContent>
                      <div className="flex  flex-col items-center">
                        <Typography variant="h6" gutterBottom>
                          Add Temporary Address
                        </Typography>

                        <div className="">
                          <IconButton
                            onClick={() =>
                              handleOpenAddressEditDialog("temporary")
                            }
                          >
                            {" "}
                            <BiPlusCircle size={30} />
                          </IconButton>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                )}

                {/* Billing Address */}
                {form?.addresses?.billing ? (
                  <Card className="relative" style={{ margin: "16px 0" }}>
                    <CardContent>
                      <div className="flex  items-center">
                        <div className="flex-[12]">
                          <Typography variant="h6" gutterBottom>
                            Billing Address
                          </Typography>
                          <Typography>
                            <strong>City:</strong>{" "}
                            {form?.addresses?.billing?.city}
                          </Typography>
                          <Typography>
                            <strong>Street:</strong>{" "}
                            {form?.addresses?.billing?.street}
                          </Typography>
                          <Typography>
                            <strong>Post Code:</strong>{" "}
                            {form?.addresses?.billing?.post_code}
                          </Typography>
                        </div>
                        <div className="flex-[1]">
                          <IconButton
                            onClick={() =>
                              handleOpenAddressEditConfirmDialog("billing")
                            }
                          >
                            {" "}
                            <ChevronRight />
                          </IconButton>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <Card className="relative" style={{ margin: "16px 0" }}>
                    <CardContent>
                      <div className="flex  flex-col items-center">
                        <Typography variant="h6" gutterBottom>
                          Add Billing Address
                        </Typography>

                        <div className="">
                          <IconButton
                            onClick={() =>
                              handleOpenAddressEditDialog("billing")
                            }
                          >
                            {" "}
                            <BiPlusCircle size={30} />
                          </IconButton>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                )}
              </div>
            )}

            <div className="fixed left-0 bg-white p-2  bottom-0 w-full flex justify-center z-[99] ">
              <PrimaryButton
                active
                name="Save"
                type="submit"
                className="w-full max-w-xs  "
                action={onSubmit}
              />
            </div>
          </div>
        </div>
      </>

      <FormProvider {...methods}>
        {" "}
        <AddressDialog
          open={addressEditDialogOpen?.open}
          type={addressEditDialogOpen?.type}
          onClose={handleCloseAddressEditDialog}
          onConfirm={updateAddress}
        />
      </FormProvider>
      <AddressModifyConfirmDialog
        open={addressEditConfirmDialogOpen?.open}
        type={addressEditConfirmDialogOpen?.type}
        onEdit={handleOpenAddressEditDialog}
        onClose={handleCloseAddressEditConfirmDialog}
        onDelete={RemoveAddress}
      />
    </div>
  );
}

export default AddFamilyMembers;

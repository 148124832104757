import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import avatar from "@/../../public/assets/images/avatar.png";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";

import Alert from "@mui/material/Alert";
import { FaRegEdit } from "react-icons/fa";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import { MdOutlineCalendarToday } from "react-icons/md";
import SelectDialog from "@medoczen/core/SelectDialog/SelectDialog";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { Tabs, Tab } from "@mui/material"; // Import Tabs and Tab
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getPersonalDetails,
  updatePersonalDetails,
} from "./store/personalDetailsSlice";
import CountriesDropdown from "@medoczen/core/CountriesDropdown/CountriesDropdown";
import { setUser } from "@medoczen/core/MDZsessions";
import SkeletonPage from "./SkeletonPage";
import DropDownSheet from "@medoczen/core/DropDownSheet/DropDownSheet";
import VerifiedIcon from "@mui/icons-material/Verified";
import ConfirmDialog from "@medoczen/core/ConfirmDialog/ConfirmDialog";
import { ChevronRight } from "@mui/icons-material";
import AddressDialog from "./AddressDialog";
import AddressModifyConfirmDialog from "./AddressModifyConfirmDialog";
import { BiPlusCircle } from "react-icons/bi";

function PersonalDetails(props) {
  const methods = useForm();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,

    formState: { errors, isDirty },
  } = methods;
  const form = watch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addressEditConfirmDialogOpen, setAddressEditConfirmDialogOpen] =
    useState({ type: "", open: false });
  const [addressEditDialogOpen, setAddressEditDialogOpen] = useState({
    type: "",
    open: false,
  });

  const selectUser = useSelector(
    (state) => state.personalDetails.personalDetailsSlice
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tempImage, setTempImage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (snackbarOpen) {
      timer = setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [snackbarOpen]);

  useEffect(() => {
    if (selectUser?.personalDetails_success) {
      setLoading(false);
      setUser({
        ...selectUser?.personalDetails_success,
        user_id: selectUser?.personalDetails_success?.id,
      });
      reset(selectUser?.personalDetails_success);
    } else if (selectUser?.personalDetails_error) {
      setLoading(false);

      if (
        selectUser?.personalDetails_error?.name === "NotAuthorizedException"
      ) {
        setSnackbarMessage("Username/Password is incorrect");
        setSnackbarOpen(true);
      } else if (
        selectUser?.personalDetails_error?.CodeDeliveryDetails?.Destination
      ) {
        // Handle code sent scenario
        setSnackbarMessage(
          `Code sent to ${selectUser.personalDetails_error.CodeDeliveryDetails.Destination}`
        );
        setSnackbarOpen(true);
      } else if (
        selectUser.personalDetails_error.name === "LimitExceededException"
      ) {
        setSnackbarMessage(
          `Request limit exceeded. Try again in 15 minute(s).`
        );
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate, setSnackbarMessage, setSnackbarOpen]);

  useEffect(() => {
    setLoading(true);
    dispatch(getPersonalDetails());
  }, [dispatch]);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(updatePersonalDetails(form)).then(() => {
      setTempImage(null);
    });
  };

  const updateAddress = (data) => {
    setLoading(true);
    setAddressEditDialogOpen({ type: "", open: false });
    dispatch(updatePersonalDetails(form)).then(() => {
      setTempImage(null);
    });
  };

  function RemoveAddress(type) {
    const updatedAddresses = { ...form.addresses };

    // Remove the address for the given type
    if (updatedAddresses[type]) {
      delete updatedAddresses[type];
    }

    // Update the form state with the modified addresses
    setValue("addresses", updatedAddresses);

    // Show loading and close dialog
    setLoading(true);
    setAddressEditConfirmDialogOpen({ type: "", open: false });

    // Dispatch the updated details
    dispatch(
      updatePersonalDetails({ ...form, addresses: updatedAddresses })
    ).then(() => {
      setTempImage(null);
      setLoading(false);
    });
  }

  // State and handler for tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleOpenAddressEditDialog(type) {
    setAddressEditConfirmDialogOpen({
      type: "",
      open: false,
    });
    setAddressEditDialogOpen({ type, open: true });
  }

  function handleCloseAddressEditDialog() {
    setAddressEditDialogOpen({ type: "", open: false });
  }
  function handleOpenAddressEditConfirmDialog(type) {
    setAddressEditConfirmDialogOpen({
      type,
      open: true,
    });
  }
  function handleCloseAddressEditConfirmDialog() {
    setAddressEditConfirmDialogOpen({ type: "", open: false });
  }

  if (loading) {
    return <SkeletonPage />;
  }

  return (
    <>
      <HeaderWrapper title="Personal Details" path={"settings"} />
      <div className="flex flex-col items-center px-4 py-3 w-full">
        {/* PersonalDetails Image Upload */}
        <div className="w-full flex justify-center pb-5">
          <Controller
            name="selfie"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <label className="relative">
                <img
                  src={
                    tempImage
                      ? tempImage
                      : `${
                          form?.selfie
                            ? `${form.selfie}?${new Date().getTime()}`
                            : `${avatar}?${new Date().getTime()}`
                        }`
                  }
                  className="w-[100px] h-[100px] rounded-full"
                />

                <input
                  id="image"
                  name={name}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setTempImage(reader.result);
                        onChange({
                          name: file.name,
                          type: file.type,
                          file: reader.result,
                        });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  onBlur={onBlur}
                  ref={ref}
                />
                <div className="absolute right-0 bottom-0 bg-white w-[40px] h-[40px] rounded-full flex justify-center items-center shadow-md cursor-pointer">
                  <FaRegEdit size={20} />
                </div>
              </label>
            )}
          />
        </div>

        {form?.nhs_verified && <VerifiedIcon color="primary" />}

        {/* Snackbar Alert */}
        {snackbarOpen && (
          <div>
            <Alert className="mt-2" variant="filled" severity="error">
              {snackbarMessage}
            </Alert>
          </div>
        )}

        {/* Tabs Section */}
        <div className="w-full  pb-5">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ textTransform: "none" }}
            variant="fullWidth"
          >
            <Tab label="General" />
            <Tab label="Address" />
            {/* <Tab label="Tab Three" /> */}
          </Tabs>
        </div>

        {/* Rest of the form */}
        {tabValue === 0 && (
          <div className="w-full">
            <div className="mb-3">
              <Controller
                name="full_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField {...field} label="Name" className="mb-2" />
                )}
              />
            </div>

            <div className="mb-3">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    disabled={true}
                    label="Email"
                    className="mb-2"
                  />
                )}
              />
            </div>

            <div className="mb-3">
              <Controller
                name="birthday"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MDatePicker
                    label="Birthday"
                    name="birthday"
                    control={control}
                    icon={<MdOutlineCalendarToday size={24} />}
                    className="mb-2"
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Controller
                name="gender_id"
                control={control}
                render={({ field }) => (
                  <DropDownSheet
                    field={field}
                    label="Gender"
                    options={[
                      { value: "1", label: "Male" },
                      { value: "2", label: "Female" },
                      { value: "3", label: "Other" },
                    ]}
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    type="text"
                    label="Phone"
                    className="mb-2"
                  />
                )}
              />
            </div>
            {/* Country Dropdown */}
            <div className="mb-[7rem]">
              <FormControl fullWidth>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CountriesDropdown
                      label="Country"
                      field={field}
                      setValue={setValue}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="fixed bottom-0 bg-white p-2 left-0 w-full flex justify-center z-[99]">
              <PrimaryButton
                active
                name="Save"
                type="submit"
                className="w-full max-w-xs "
                action={onSubmit}
              />
            </div>
          </div>
        )}
        {tabValue === 1 && (
          <div className="w-full">
            {/* Home Address */}

            {form?.addresses?.home ? (
              <Card className="relative" style={{ margin: "16px 0" }}>
                <CardContent>
                  <div className="flex  items-center">
                    <div className="flex-[12]">
                      <Typography variant="h6" gutterBottom>
                        Home Address
                      </Typography>
                      <Typography>
                        <strong>City:</strong> {form?.addresses?.home?.city}
                      </Typography>
                      <Typography>
                        <strong>Street:</strong> {form?.addresses?.home?.street}
                      </Typography>
                      <Typography>
                        <strong>Post Code:</strong>{" "}
                        {form?.addresses?.home?.post_code}
                      </Typography>
                    </div>
                    <div className="flex-[1]">
                      <IconButton
                        onClick={() =>
                          handleOpenAddressEditConfirmDialog("home")
                        }
                      >
                        {" "}
                        <ChevronRight />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card className="relative" style={{ margin: "16px 0" }}>
                <CardContent>
                  <div className="flex  flex-col items-center">
                    <Typography variant="h6" gutterBottom>
                      Add Home Address
                    </Typography>

                    <div className="">
                      <IconButton
                        onClick={() => handleOpenAddressEditDialog("home")}
                      >
                        {" "}
                        <BiPlusCircle size={30} />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {/* Temporary Address */}
            {form?.addresses?.temporary ? (
              <Card className="relative" style={{ margin: "16px 0" }}>
                <CardContent>
                  <div className="flex  items-center">
                    <div className="flex-[12]">
                      <Typography variant="h6" gutterBottom>
                        Temporary Address
                      </Typography>
                      <Typography>
                        <strong>City:</strong>{" "}
                        {form?.addresses?.temporary?.city}
                      </Typography>
                      <Typography>
                        <strong>Street:</strong>{" "}
                        {form?.addresses?.temporary?.street}
                      </Typography>
                      <Typography>
                        <strong>Post Code:</strong>{" "}
                        {form?.addresses?.temporary?.post_code}
                      </Typography>
                    </div>
                    <div className="flex-[1]">
                      <IconButton
                        onClick={() =>
                          handleOpenAddressEditConfirmDialog("temporary")
                        }
                      >
                        {" "}
                        <ChevronRight />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card className="relative" style={{ margin: "16px 0" }}>
                <CardContent>
                  <div className="flex  flex-col items-center">
                    <Typography variant="h6" gutterBottom>
                      Add Temporary Address
                    </Typography>

                    <div className="">
                      <IconButton
                        onClick={() => handleOpenAddressEditDialog("temporary")}
                      >
                        {" "}
                        <BiPlusCircle size={30} />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {/* Billing Address */}
            {form?.addresses?.billing ? (
              <Card className="relative" style={{ margin: "16px 0" }}>
                <CardContent>
                  <div className="flex  items-center">
                    <div className="flex-[12]">
                      <Typography variant="h6" gutterBottom>
                        Billing Address
                      </Typography>
                      <Typography>
                        <strong>City:</strong> {form?.addresses?.billing?.city}
                      </Typography>
                      <Typography>
                        <strong>Street:</strong>{" "}
                        {form?.addresses?.billing?.street}
                      </Typography>
                      <Typography>
                        <strong>Post Code:</strong>{" "}
                        {form?.addresses?.billing?.post_code}
                      </Typography>
                    </div>
                    <div className="flex-[1]">
                      <IconButton
                        onClick={() =>
                          handleOpenAddressEditConfirmDialog("billing")
                        }
                      >
                        {" "}
                        <ChevronRight />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card className="relative" style={{ margin: "16px 0" }}>
                <CardContent>
                  <div className="flex  flex-col items-center">
                    <Typography variant="h6" gutterBottom>
                      Add Billing Address
                    </Typography>

                    <div className="">
                      <IconButton
                        onClick={() => handleOpenAddressEditDialog("billing")}
                      >
                        {" "}
                        <BiPlusCircle size={30} />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {/* Save Button */}
            <div className="fixed bottom-0 bg-white p-2 left-0 w-full flex justify-center z-[99]">
              <PrimaryButton
                active
                name="Save"
                type="submit"
                className="w-full max-w-xs"
                action={onSubmit}
              />
            </div>
          </div>
        )}

        <FormProvider {...methods}>
          {" "}
          <AddressDialog
            open={addressEditDialogOpen?.open}
            type={addressEditDialogOpen?.type}
            onClose={handleCloseAddressEditDialog}
            onConfirm={updateAddress}
          />
        </FormProvider>
        <AddressModifyConfirmDialog
          open={addressEditConfirmDialogOpen?.open}
          type={addressEditConfirmDialogOpen?.type}
          onEdit={handleOpenAddressEditDialog}
          onClose={handleCloseAddressEditConfirmDialog}
          onDelete={RemoveAddress}
        />
      </div>
    </>
  );
}

export default PersonalDetails;

import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from "@mui/material";
import { MdOutlineCalendarToday } from "react-icons/md";

import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";

import { NhsVerificationSend } from "./store/nhsverificationSlice";
import { setUser } from "../MDZsessions";

export default function NhsVerificationDialog({
  user_id,
  user_data,
  is_patient,
  is_member,
  action,
}) {
  // React Hook Form setup
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();

  // Local states
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    severity: "",
  });

  const dispatch = useDispatch();

  /**
   * Intercept the browser/mobile back button.
   * If the dialog is open, close it instead of navigating away.
   */
  useEffect(() => {
    // If the dialog just opened, push a dummy state into history.
    if (open) {
      window.history.pushState({ dialogOpened: true }, "");
    }

    // Handler for "popstate" (back button)
    const handlePopState = () => {
      
      // If the dialog is open when the user presses back, close it
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    // Cleanup
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [open]);

  /**
   * Submits the form data to your NHS verification process
   */
  const onSubmit = (data) => {
    if (!user_id) {
      return alert("User ID is required");
    }
    setLoading(true);

    // Convert birthday to YYYY-MM-DD
    let date = new Date(data.birthday);
    const dob =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");

    const payload = {
      ...data,
      birthday: dob,
      user_id: user_id,
    };

    // Dispatch the verification action
    dispatch(NhsVerificationSend(payload)).then((res) => {
      if (res?.payload?.updateUserDetails?.nhs_verified) {
        // NHS verification succeeded
        setSnackbarMessage({
          message: "NHS verification successful",
          severity: "success",
        });
        setSnackbarOpen(true);

        // If user is a patient, update local user data
        if (is_patient) {
          setUser({ ...user_data, user_id: user_data?.id, nhs_verified: true });
        }
        // If user is a member, run the passed-in action callback
        if (is_member) {
          action();
        }
        onClose();
      } else {
        // Verification failed
        setSnackbarMessage({
          message: "NHS verification failed",
          severity: "error",
        });
        setSnackbarOpen(true);
      }
      setLoading(false);
    });
  };

  /**
   * Close the dialog and optionally reset form fields.
   */
  const onClose = () => {
    setOpen(false);
    // Optionally reset the form here if desired:
    // reset();
  };

  /**
   * When the user clicks the button (Chip), open the dialog.
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      {/* Chip that opens the dialog */}
      <Chip
        onClick={handleClickOpen}
        label="Connect to NHS"
        size="small"
        color="primary"
      />

      {/* Material-UI Dialog */}
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Connect with NHS</DialogTitle>

        <DialogContent>
          {/* Alert for success/error messages */}
          {snackbarOpen && (
            <Alert
              className="mt-2"
              variant="filled"
              severity={snackbarMessage.severity}
            >
              {snackbarMessage.message}
            </Alert>
          )}

          <Box mt={2}>
            {/* NHS Number Field */}
            <div className="mb-3">
              <Controller
                name="nhs_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    label="NHS Number"
                    className="mb-2"
                  />
                )}
              />
            </div>

            {/* Birthday Field */}
            <div className="mb-3">
              <Controller
                name="birthday"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MDatePicker
                    label="Birthday"
                    name="birthday"
                    control={control}
                    icon={<MdOutlineCalendarToday size={24} />}
                    className="mb-2"
                  />
                )}
              />
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* Button to submit the form */}
            <PrimaryButton
              active={!loading}
              name="Connect"
              type="submit"
              disabled={loading}
              loading={loading}
              className="mx-auto"
              action={handleSubmit(onSubmit)}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// components/AllergyCard.js
import React from "react";
import { IoTriangle } from "react-icons/io5";
import { CiClock2 } from "react-icons/ci";
import PropTypes from "prop-types";

function AllergyCard({ allergy }) {
  return (
    <div className="relative min-h-[100px] rounded-md bg-white shadow-md flex flex-col justify-start items-start p-4 gap-3 w-full">
      <div className="flex justify-between w-full">
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold text-gray-800">{allergy.description}</h2>
          <div className="flex flex-wrap gap-2 mt-1">
            <span className="text-sm text-gray-600">
              <strong>Date Recorded:</strong> {allergy.date || "N/A"}
            </span>
            {allergy.certainty && (
              <span className="text-sm text-gray-600">
                <strong>Certainty:</strong> {allergy.certainty}
              </span>
            )}
            {allergy.severity && (
              <span className="text-sm text-gray-600">
                <strong>Severity:</strong> {allergy.severity}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end">
          <CiClock2 className="text-gray-500" size={20} />
          <span className="text-sm text-gray-500">{allergy.supportingInformation}</span>
        </div>
      </div>
      <div className="text-sm text-gray-700">
        {allergy.note || "No additional information provided."}
      </div>
      {/* Status Indicator */}
      {allergy.status && (
        <div
          className="absolute top-4 right-4 flex items-center gap-1"
          style={{ color: allergy.statusColor }}
        >
          <IoTriangle size={16} />
          <span className="text-sm font-medium">{allergy.status}</span>
        </div>
      )}
    </div>
  );
}

AllergyCard.propTypes = {
  allergy: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string.isRequired,
    certainty: PropTypes.string,
    severity: PropTypes.string,
    supportingInformation: PropTypes.string,
    note: PropTypes.string,
    status: PropTypes.string,
    statusColor: PropTypes.string,
  }).isRequired,
};

export default AllergyCard;

import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { CgSortAz } from "react-icons/cg";

export default function SortTab() {
  const [sort, setSort] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="relative">
      <Button
        style={{
          textTransform: "none",
          backgroundColor: "#00854221",
          color: "black",
          // zIndex: 100,
          width: "74px",
          height: "45px",
        }}
        onClick={handleOpen}
      >
        <CgSortAz size={26} />{" "}
        <span className="text-[14px] font-[500]">Sort</span>
      </Button>

      <div className="absolute top-[1rem] left-[1rem] opacity-0">
        <Select
          className="w-[1rem]"
          id=""
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={sort}
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value={10}>Ascending</MenuItem>
          <MenuItem value={20}>Desecending</MenuItem>
        </Select>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import SortTab from "./SortTab";
import ObservationCard from "./ObservationCard";

function Observations(props) {
  const [observations, setObservations] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchObservations = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "/patient-care-record/observations?composition_identifier=FA60BE64-1F34-11EB-A2A8-000C29A364EB&patient_nhs_number=9000000009",
          {
            params: { limit: pagination.limit, offset: pagination.offset },
          }
        );
        setObservations(response.data.data);
        setPagination(response.data.pagination);
        setError(null);
      } catch (error) {
        console.error("Error fetching observations:", error);
        setError("Failed to fetch observations.");
      } finally {
        setLoading(false);
      }
    };

    fetchObservations();
  }, [pagination.limit, pagination.offset]);

  return (
    <>
      <HeaderWrapper title="Observations" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        <div className="flex items-center w-full gap-[1rem]">
          <SearchBar placeholder="Search Here" />
          <SortTab />
        </div>
        <div className="w-full">
          <div className="w-full ps-[2px] pe-[2rem]">
            <h1 className="text-primary font-[500] text-[12px] ">
              Last Updated: {new Date().toLocaleString()}
            </h1>
            <span className="text-[#000000b0] font-[400] text-[12px] ">
              Pull down page to check for new records
            </span>
          </div>
          <hr className="border-[#0000008c]  w-full" />
        </div>
        {loading && <p>Loading observations...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && observations.length === 0 && (
          <p>No observations found.</p>
        )}
        {!loading &&
          !error &&
          observations.map((observation) => (
            <ObservationCard key={observation.id} observation={observation} />
          ))}
      </div>
      {/* Pagination Controls */}
      <div className="fixed bg-white bottom-0 py-2 px-3 left-0 flex justify-between items-center w-full mt-4">
        <button
          onClick={() =>
            setPagination({
              ...pagination,
              offset: pagination.offset - pagination.limit,
            })
          }
          disabled={pagination.offset === 0}
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {Math.floor(pagination.offset / pagination.limit) + 1} of{" "}
          {Math.ceil(pagination.total / pagination.limit)}
        </span>
        <button
          onClick={() =>
            setPagination({
              ...pagination,
              offset: pagination.offset + pagination.limit,
            })
          }
          disabled={pagination.offset + pagination.limit >= pagination.total}
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </>
  );
}

export default Observations;

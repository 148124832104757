// Practitioners.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import SortTab from "./SortTab";
import PractitionerCard from "./PractitionerCard";

function Practitioners(props) {
  const [practitioners, setPractitioners] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("");

  useEffect(() => {
    const fetchPractitioners = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "/patient-care-record/practitioners?composition_identifier=FA60BE64-1F34-11EB-A2A8-000C29A364EB&patient_nhs_number=9000000009",
          {
            params: {
              limit: pagination.limit,
              offset: pagination.offset,
              search: searchQuery, // Assuming your backend supports search
              sort: sortOption, // Assuming your backend supports sorting
            },
          }
        );
        setPractitioners(response.data.data);
        setPagination((prev) => ({
          ...prev,
          total: response.data.pagination.total,
        }));
        setError(null);
      } catch (err) {
        console.error("Error fetching practitioners:", err);
        setError("Failed to fetch practitioners.");
      } finally {
        setLoading(false);
      }
    };

    fetchPractitioners();
  }, [pagination.limit, pagination.offset, searchQuery, sortOption]);

  // Handler for search input
  const handleSearch = (query) => {
    setSearchQuery(query);
    setPagination((prev) => ({ ...prev, offset: 0 })); // Reset to first page on search
  };

  // Handler for sort option
  const handleSort = (option) => {
    setSortOption(option);
    setPagination((prev) => ({ ...prev, offset: 0 })); // Reset to first page on sort
  };

  // Handlers for pagination buttons
  const handlePreviousPage = () => {
    setPagination((prev) => ({
      ...prev,
      offset: Math.max(prev.offset - prev.limit, 0),
    }));
  };

  const handleNextPage = () => {
    setPagination((prev) => ({
      ...prev,
      offset: prev.offset + prev.limit,
    }));
  };

  return (
    <>
      <HeaderWrapper title="Practitioners" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        <div className="flex items-center w-full gap-[1rem]">
          <SearchBar placeholder="Search Here" onSearch={handleSearch} />
          <SortTab onSort={handleSort} />
        </div>
        <div className="w-full">
          <div className="w-full ps-[2px] pe-[2rem]">
            <h1 className="text-primary font-[500] text-[12px] ">
              Last Updated: {new Date().toLocaleString()}
            </h1>
            <span className="text-[#000000b0] font-[400] text-[12px] ">
              Pull down page to check for new records
            </span>
          </div>
          <hr className="border-[#0000008c] w-full" />
        </div>
        {loading && <p>Loading practitioners...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && practitioners.length === 0 && (
          <p>No practitioners found.</p>
        )}
        {!loading &&
          !error &&
          practitioners.map((practitioner) => (
            <PractitionerCard
              key={practitioner.id}
              practitioner={practitioner}
            />
          ))}
        {/* Pagination Controls */}
      </div>

      <div className="fixed bg-white bottom-0 py-2 px-3 left-0 flex justify-between items-center w-full mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={pagination.offset === 0}
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {Math.floor(pagination.offset / pagination.limit) + 1} of{" "}
          {Math.ceil(pagination.total / pagination.limit)}
        </span>
        <button
          onClick={handleNextPage}
          disabled={pagination.offset + pagination.limit >= pagination.total}
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </>
  );
}

export default Practitioners;

import React from "react";
import { CiClock2 } from "react-icons/ci";

const DiagnosisCard = ({ diagnosis }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'entered-in-error':
        return '#FF0000'; // Red
      case 'confirmed':
        return '#00FF00'; // Green
      default:
        return '#0000FF'; // Blue
    }
  };

  return (
    <div
      key={diagnosis.id}
      className="relative min-h-[70px] rounded-[6px] bg-[#FAFAFA] flex flex-col justify-start items-start p-[1rem] gap-[0.5rem] w-full"
    >
      <div className="flex justify-between w-full">
        <div className="flex flex-col w-fit">
          <div
            style={{ backgroundColor: "#4A90E2" }} // Customize as needed
            className="flex justify-center items-center text-[17px] font-[600] text-white rounded-full w-[49px] h-[49px]"
          >
            <span>{diagnosis.diagnosis.charAt(0)}</span>
          </div>
          <h1 className="text-[16px] font-[500] mt-[0.5rem]">
            {diagnosis.diagnosis}
          </h1>
          <span className="text-[12px] font-[400]">
            Onset: {new Date(diagnosis.onsetDate).toLocaleDateString()}
          </span>
        </div>
        <div className="flex flex-col justify-center">
          <span
            className="text-[12px] font-[400]"
            style={{ color: getStatusColor(diagnosis.verificationStatus) }}
          >
            {diagnosis.verificationStatus}
          </span>
        </div>
      </div>
      <div className="text-[12px] font-[400] text-gray-600 mt-[0.5rem]">
        Encounter: {diagnosis.encounter}
      </div>
      {diagnosis.note && (
        <div className="text-[12px] font-[400] text-gray-600 mt-[0.5rem]">
          Note: {diagnosis.note}
        </div>
      )}
      <div className="absolute right-[5px] top-[5px] flex flex-col h-full py-[5px] justify-between items-end">
        <CiClock2 />
        <span
          className="text-[12px]"
          style={{ color: getStatusColor(diagnosis.verificationStatus) }}
        >
          {diagnosis.verificationStatus}
        </span>
      </div>
    </div>
  );
};

export default DiagnosisCard;

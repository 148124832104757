import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

export default function AddressModifyConfirmDialog({
  open,
  type,
  onClose,
  onEdit,
  onDelete,
}) {
  return (
    <Dialog open={open ?? false} onClose={onClose}>
      <DialogContent>
        <Typography>Are you sure you want to modify {type} address?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDelete(type?.toLowerCase())} color="secondary">
          Delete
        </Button>
        <Button onClick={() => onEdit(type)} color="primary">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useRef, useEffect } from "react";
import {
  FaQuestionCircle,
  FaEye,
  FaSyringe,
  FaPills,
  FaExclamationTriangle,
  FaUserMd,
  FaFileMedical,
} from "react-icons/fa";
import { GiLifeBar } from "react-icons/gi";
import { RxCross1, RxDashboard } from "react-icons/rx";
import { PiNotepad } from "react-icons/pi";

import avatar2 from "@/../../public/assets/images/Dashboard/Avatar/avatar2.png";
import { Link, useLocation } from "react-router-dom";
import { getUser } from "@medoczen/core/MDZsessions";
import NhsVerificationDialog from "@medoczen/core/NHSVerification/NhsVerification";
import VerifiedIcon from "@mui/icons-material/Verified";
import { MdOutlineAcUnit } from "react-icons/md";
import { Divider } from "@mui/material";

const sidebarItems = [
  {
    path: "/dashboard",
    icon: <RxDashboard size={24} />,
    label: "Dashboard",
  },
  {
    path: "/care-records/allergies",
    icon: <MdOutlineAcUnit size={24} />,
    label: "Allergies",
  },
  {
    path: "/care-records/diagnoses",
    icon: <FaFileMedical size={24} />,
    label: "Diagnoses",
  },
  {
    path: "/care-records/observations",
    icon: <FaEye size={24} />,
    label: "Observations",
  },
  {
    path: "/care-records/immunization",
    icon: <FaSyringe size={24} />,
    label: "Immunization",
  },
  {
    path: "/care-records/treatments",
    icon: <FaPills size={24} />,
    label: "Treatments",
  },
  {
    path: "/care-records/risks",
    icon: <FaExclamationTriangle size={24} />,
    label: "Risks",
  },
  {
    path: "/care-records/practitioners",
    icon: <FaUserMd size={24} />,
    label: "Practitioners",
  },
  {
    path: "/lab-reports",
    icon: <PiNotepad size={24} />,
    label: "Reports",
  },
  {
    path: "/health-assessment",
    icon: <GiLifeBar size={24} />,
    label: "Health Assessment",
  },
  {
    path: "/help-support",
    icon: <FaQuestionCircle size={24} />,
    label: "Help & Support",
  },
];

function Sidebar(props) {
  const location = useLocation(); // Get the current location

  // Function to determine if the item is active
  const isActive = (path) => location.pathname === path;
  const user_data = getUser();
  const sidebarRef = useRef(); // Step 1: useRef to reference the sidebar

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        props.setSidebarOpen(false);
      }
    };

    // Attach the event listener only if the sidebar is open
    if (props.isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.isSidebarOpen, props.setSidebarOpen]); // Ensure the effect runs when `isSidebarOpen` changes

  return (
    <div
      style={{ zIndex: 500 }}
      className={`fixed top-0 flex h-screen w-[100%] transition-transform duration-300 ${
        props?.isSidebarOpen ? "" : "translate-x-[-100%]"
      }`}
    >
      <div
        ref={sidebarRef}
        className="relative w-[80%] h-full bg-white flex flex-col px-[1rem] pb-[2rem] pt-[1rem]"
      >
        <div className="w-full flex flex-col items-start gap-1 justify-start">
          <img
            src={`${
              user_data?.selfie ? user_data.selfie : avatar2
            }?${new Date().getTime()}`}
            className="w-[65px] h-[65px] rounded-full"
            alt="Profile preview"
          />

          <div>
            <h1 className="text-[16px] font-semibold">
              {user_data?.full_name}{" "}
              {user_data?.nhs_verified ? <VerifiedIcon color="primary" /> : ""}
            </h1>
            {!user_data?.nhs_verified && (
              <NhsVerificationDialog
                is_patient={true}
                user_id={user_data?.user_id}
                user_data={user_data}
              />
            )}
          </div>
        </div>
        <Divider sx={{ mt: 1 }} />

        <ul className="mt-[2rem] ms-[10px] flex flex-col gap-[1.5rem]">
          {sidebarItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`flex items-center gap-3 text-[18px] font-[500] ${
                  isActive(item.path) ? "text-activeLink" : "text-inactiveLink"
                }`}
              >
                {item.icon}
                <span>{item.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;

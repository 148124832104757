// PractitionerCard.js
import React from "react";
import { CiClock2 } from "react-icons/ci";

/**
 * Determines the background color based on the practitioner's role.
 * Customize this function as needed.
 */
const getRoleColor = (roles) => {
    if (roles.toLowerCase().includes("consultant")) {
        return '#4A90E2'; // Blue for Consultant
    }
    if (roles.toLowerCase().includes("director")) {
        return '#28a745'; // Green for Director
    }
    if (roles.toLowerCase().includes("assistant")) {
        return '#ffc107'; // Yellow for Assistant
    }
    return '#6c757d'; // Gray for other roles
};

const PractitionerCard = ({ practitioner }) => {
    return (
        <div
            className="relative min-h-[80px] rounded-[6px] bg-[#FAFAFA] flex flex-col justify-start items-start p-[1rem] gap-[0.5rem] w-full"
        >
            <div className="flex justify-between w-full">
                <div className="flex flex-col w-fit">
                    <div
                        style={{ backgroundColor: getRoleColor(practitioner.roles) }}
                        className="flex justify-center items-center text-[17px] font-[600] text-white rounded-full w-[49px] h-[49px]"
                    >
                        <span>{practitioner.name?.charAt(0) || 'N'}</span>
                    </div>
                    <h1 className="text-[16px] font-[500] mt-[0.5rem]">
                        {practitioner.name || 'N/A'}
                    </h1>
                    <span className="text-[12px] font-[400]">
                        Roles: {practitioner.roles || 'N/A'}
                    </span>
                </div>
                <div className="flex flex-col justify-center">
                    {practitioner.organization !== 'N/A' && (
                        <span
                            className="text-[12px] font-[400]"
                            style={{ color: "#28a745" }} // Green color for organization
                        >
                            {practitioner.organization}
                        </span>
                    )}
                </div>
            </div>
            <div className="text-[12px] font-[400] text-gray-600 mt-[0.5rem]">
                Identifiers: {practitioner.identifiers.length > 0 ? practitioner.identifiers.map(id => `${id.system}: ${id.value}`).join(', ') : 'N/A'}
            </div>
            <div className="absolute right-[5px] top-[5px] flex flex-col h-full py-[5px] justify-between items-end">
                <CiClock2 />
                {/* Optional: Additional Info */}
            </div>
        </div>
    );
};

export default PractitionerCard;

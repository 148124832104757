import React, { useEffect, useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { FiPlusCircle } from "react-icons/fi";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import CustomButton from "@medoczen/core/Buttons/CustomButton";
import avatar1 from "@/../../public/assets/images/placeholder.png";
import { Box, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMembers } from "./store/membersSlice";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import VerifiedIcon from "@mui/icons-material/Verified";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className=" w-full"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Members(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  const selectMembers = useSelector((state) => state.member.membersSlice);

  useEffect(() => {
    setMembers(selectMembers.getMember_success);
  }, [selectMembers]);

  const ButtonClick = (e) => {
    e.preventDefault();
    props.navigate("/profile");
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const HandleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const filter = {
    is_verified: tabValue === 0 ? true : tabValue === 1 ? false : false,
    name: searchText,
  };

  function fetchData() {
    setLoading(true);
    dispatch(getMembers(filter))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error fetching members:", error);
        setLoading(false); // Ensure loading state is set to false in case of error
      });
  }

  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ country_code }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(country_code);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img
        src={flagSrc}
        className="absolute top-[5px] left-[5px] w-[15px] h-[10px]"
      />
    );
  };

  function HandleEdit(item) {
    props.navigate(`add-family-members/${item.id}`);
  }

  useEffect(() => {
    fetchData();
  }, [tabValue, searchText]);

  const relationshipTypes = {
    1: "Mother",
    2: "Father",
    3: "Son",
    4: "Daughter",
    5: "Uncle",
    6: "Aunt",
    7: "Sister",
    8: "Brother",
  };

  return (
    <>
      <HeaderWrapper title="Family Care" path="settings">
        <Link to={"/add-family-members"} className="mt-[5px]">
          <FiPlusCircle color="white" size={30} />
        </Link>
      </HeaderWrapper>

      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        <div className="flex items-center w-full gap-[1rem]">
          <SearchBar
            onChange={HandleSearch}
            value={searchText}
            placeholder="Search Here"
          />
        </div>
        <div className="w-full">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                style={{ textTransform: "none", color: "black" }}
                label="Verified"
                {...a11yProps(0)}
              />
              <Tab
                style={{ textTransform: "none", color: "black" }}
                label="Unverified "
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </div>

        <CustomTabPanel value={tabValue} index={0}>
          <div className="flex flex-col w-full gap-6 justify-center items-center">
            {loading ? (
              <>
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
              </>
            ) : (
              <>
                {members.map((member, index) => (
                  <div
                    onClick={() => HandleEdit(member)}
                    key={index}
                    className="relative w-full h-[97px] px-[12px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0]"
                  >
                    <div className="flex justify-center items-center">
                      <div className="relative flex flex-col w-fit pe-[1rem]">
                        <img
                          src={member.selfie || avatar1}
                          className="w-[77px] h-[79px] object-cover"
                          alt=""
                        />
                        <CountryFlag country_code={member.country_code} />
                      </div>
                      <div className="flex flex-col justify-start items-start h-full w-fit">
                        <h1 className="text-[14px] font-[600]">
                          {member.full_name}
                        </h1>
                        <span className="text-[12px]">
                          {new Date(member.birthday).toLocaleDateString()}
                        </span>
                        {/* <span className="text-[12px]">
                        {member.gender_id === 1 ? "Male" : member.gender_id === 2 ? "Female" : member.gender_id === 3 ? "Others" : ""}
                      
                      </span> */}
                        <span className="text-[12px]">
                          {
                            relationshipTypes[
                              member.family_relationship_type_id
                            ]
                          }
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col justify-around h-full items-center py-[5px]">
                      {member?.nhs_verified ? (
                        <VerifiedIcon fontSize="medium" color="primary" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={1}>
          <div className="flex flex-col w-full gap-6 justify-center items-center">
            {loading ? (
              <>
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%", height: "97px" }}
                />
              </>
            ) : (
              <>
                {members.map((member, index) => (
                  <div
                    onClick={() => HandleEdit(member)}
                    key={index}
                    className="relative w-full h-[97px] px-[12px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0]"
                  >
                    <div className="flex justify-center items-center">
                      <div className="relative flex flex-col w-fit pe-[1rem]">
                        <img
                          src={member.selfie || avatar1}
                          className="w-[77px] h-[79px] object-cover"
                          alt=""
                        />
                        <CountryFlag country_code={member.country_code} />
                      </div>
                      <div className="flex flex-col justify-start items-start h-full w-fit">
                        <h1 className="text-[14px] font-[600]">
                          {member.full_name}
                        </h1>
                        <span className="text-[12px]">
                          {new Date(member.birthday).toLocaleDateString()}
                        </span>
                        {/* <span className="text-[12px]">
                        {member.gender_id === 1 ? "Male" : member.gender_id === 2 ? "Female" : member.gender_id === 3 ? "Others" : ""}
                      </span> */}
                        <span className="text-[12px]">
                          {" "}
                          {
                            relationshipTypes[
                              member.family_relationship_type_id
                            ]
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </CustomTabPanel>
      </div>
    </>
  );
}

export default Members;

import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InputField from "@medoczen/core/InputField/InputField";

export default function AddressDialog({ open, type, onClose, onConfirm }) {
  const { control, handleSubmit, setValue } = useFormContext();

  // Submit handler for the Save button
  const onSubmit = (data) => {
    if (onConfirm) {
      onConfirm(data); // Call the parent component's callback with the form data
    }
  };

  useEffect(() => {
    setValue(`addresses.${type?.toLowerCase()}.type`, type);
  }, [type]);

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      fullWidth
      aria-labelledby="address-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <h2 id="address-dialog-title" className="text-lg font-bold mb-4">
            {type} Address
          </h2>

          <div className="mb-3">
            <Controller
              name={`addresses.${type?.toLowerCase()}.street`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  type="text"
                  label="Street"
                  multiline
                  minRows={5}
                  className="mb-2"
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name={`addresses.${type?.toLowerCase()}.city`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  type="text"
                  label="City"
                  className="mb-2"
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name={`addresses.${type?.toLowerCase()}.post_code`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  type="text"
                  label="Postcode"
                  className="mb-2"
                />
              )}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

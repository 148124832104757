// components/Allergies.js
import React, { useEffect, useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import SortTab from "./SortTab";
import AllergyCard from "./AllergyCard"; // Import the AllergyCard component
import axios from "axios"; // Ensure axios is installed: npm install axios

function Allergies() {
  const [allergies, setAllergies] = useState([]);
  const [filteredAllergies, setFilteredAllergies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  // Search and Sort states
  const [searchQuery, setSearchQuery] = useState("");
  const [sortCriteria, setSortCriteria] = useState("Date"); // Default sort by Date

  useEffect(() => {
    const fetchAllergies = async () => {
      try {
        // Replace with your actual API endpoint
        const response = await axios.get(
          "/patient-care-record/allergies?composition_identifier=FA60BE64-1F34-11EB-A2A8-000C29A364EB&patient_nhs_number=9000000009",
          {
            params: {
              compositionIdentifier: "BF4180FD-C403-4EF7-A4AB-E07303AF5477", // Example ID
              patientNhsNumber: "9000000009",
              limit,
              offset,
            },
          }
        );

        // Check if response data exists
        if (response.data && response.data.data) {
          setAllergies(response.data.data);
        } else {
          setAllergies([]);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching allergies:", err);
        setError("Failed to load allergies data.");
        setLoading(false);
      }
    };

    fetchAllergies();
  }, [limit, offset]);

  useEffect(() => {
    let updatedAllergies = [...allergies];

    // Apply Search Filter
    if (searchQuery.trim() !== "") {
      updatedAllergies = updatedAllergies.filter((allergy) =>
        allergy.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply Sorting
    if (sortCriteria === "Date") {
      updatedAllergies.sort((a, b) => new Date(b.date) - new Date(a.date)); // Newest first
    } else if (sortCriteria === "Severity") {
      const severityOrder = { High: 3, Moderate: 2, Low: 1, "N/A": 0 };
      updatedAllergies.sort(
        (a, b) => severityOrder[b.severity] - severityOrder[a.severity]
      );
    }

    setFilteredAllergies(updatedAllergies);
  }, [allergies, searchQuery, sortCriteria]);

  if (loading) {
    return (
      <>
        <HeaderWrapper title="Allergies" />
        <div className="flex justify-center items-center h-full">
          <span>Loading...</span>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <HeaderWrapper title="Allergies" />
        <div className="flex justify-center items-center h-full">
          <span className="text-red-500">{error}</span>
        </div>
      </>
    );
  }

  // Derive last updated date from the latest allergy entry or another relevant source
  const lastUpdated = allergies.length > 0 ? allergies[0].date : "N/A";

  return (
    <>
      <HeaderWrapper title="Allergies" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-4">
        <div className="flex items-center w-full gap-4">
          <SearchBar
            placeholder="Search Allergies"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <SortTab
            value={sortCriteria}
            onChange={(e) => setSortCriteria(e.target.value)}
          />
        </div>
        <div className="w-full">
          <div className="w-full px-2 py-2">
            <h1 className="text-primary font-medium text-sm">
              Last Updated: {lastUpdated}
            </h1>
            <span className="text-gray-600 font-normal text-sm">
              Pull down page to check for new records
            </span>
          </div>
          <hr className="border-gray-500 w-full" />
        </div>
        {filteredAllergies.length === 0 ? (
          <div className="text-center text-gray-500 mt-4">
            No allergy information available.
          </div>
        ) : (
          filteredAllergies.map((allergy, index) => (
            <AllergyCard key={index} allergy={allergy} />
          ))
        )}
      </div>
      <div className=" fixed bg-white bottom-0 py-2 px-3 left-0 flex justify-between items-center w-full mt-4">
        <button
          className={`px-4 py-2 rounded ${
            offset === 0
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          onClick={() => setOffset((prev) => Math.max(prev - limit, 0))}
          disabled={offset === 0}
          aria-label="Previous Page"
        >
          Previous
        </button>
        <button
          className={`px-4 py-2 rounded ${
            allergies.length < limit
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          onClick={() => setOffset((prev) => prev + limit)}
          disabled={allergies.length < limit}
          aria-label="Next Page"
        >
          Next
        </button>
      </div>
    </>
  );
}

export default Allergies;
